.alert {
  border-radius: 10px !important;
  padding: 24px !important;
}

.alert-success {
  border: 1px solid #12B76A;
  background: #ECFDF3;
  color: #027A48 !important;
}

.alert-warning {
  border: 1px solid #F79009;
  background: #FFFAEB;
  color: #B54708;
}