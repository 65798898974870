// BOOTSTRAP 4 VARIABLES

@import "font";
@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";

// LAYOUT
@import "layout/layout";

// Icons
@import "components/icons/linearicons";

// UTILS
@import "utils/animate_helper";
@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/navs";
@import "elements/badges";
@import "elements/cards";
@import "elements/tabs";
@import "elements/accordions";
@import "elements/modals";
@import "elements/loaders";
@import "elements/progressbar";
@import "elements/timeline";
@import "elements/listgroup";
@import "elements/forms";
@import "elements/pagination";
@import "elements/chat";
@import "elements/forms-wizard";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";
@import "widgets/profile-boxes/profile-boxes";

// Responsive
@import "layout/responsive/responsive-base";

// Hamburger Button
@import "components/hamburgers/hamburgers";

// Custom
@import "custom/modal";
@import "custom/toastify";

// FORMS

@import "forms/datepicker/datepicker";

.grid__wrapper {
	margin: 0 auto;
	padding-left: 1.04166667%;
	padding-right: 1.04166667%;
	display: grid;
	grid-column-gap: 30px;
	grid-row-gap: 30px;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: auto;
}

* {
	font-size: 14px;
	font-family: "Inter";
}

.eon-main-inner {
	min-height: calc(100vh - 35px - 90px);
	padding: 0px 20px 20px 20px;
	background-color: #F9FAFB;

	@media screen and ( min-width: 768px ) {
		padding: 0px 100px 100px 100px;
	}
}

.react-datepicker__input-container input[type="text"],
.react-datepicker-wrapper input[type="text"]{
  border: none;
  outline: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}