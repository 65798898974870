// Theme White

// Variables

$app-container-bg: #ffffff;
$app-sidebar-bg: #1C212D;
$app-header-bg: #fafbfc;
$app-header-logo-bg: rgba(255, 255, 255, 0.9);

// Content

.app-theme-white {
  &.app-container {
    background: $app-container-bg;
  }

  .app-sidebar {
    background: var(--primary);
  }

  .app-page-title {
    background: #F9FAFB;
  }

  .app-footer .app-footer__inner,
  .app-header {
    background: $app-header-bg;
  }
}
