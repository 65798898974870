.custom-input-group {
  .custom-input-prefix {
    font-size: 14px;
    padding: 8px 0px 8px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #fff !important;
    border-right: 0px !important;
  }

  &:has(.custom-input-prefix) .custom-form-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: 0px !important;
  }
}

.custom-form-input,
.custom-form-input.form-control {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 300;
  align-self: stretch;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  background: #fff;
  line-height: 20px;
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &-sm {
    height: 33px !important;
  }

  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.custom-form-input-error {
  border-color: #ff4d4f !important;
  box-shadow: 0px 1px 2px 0px rgba(255, 77, 79, 0.05) !important;
}

.toggle-button {
  background: transparent;
  position: absolute;
  border: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 999;
  padding: 0 10px;
}

.custom-form-label {
  @media (min-width: 420px) {
    min-height: 22px;
    display: flex;
    align-items: center;
  }
}

.row {
  .custom-form-label {
    margin-bottom: 0px;
  }
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner{
  border-color: var(--primary)
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary)
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover:before {
    // compiled to: .form__checkbox-label:hover:before
    background-color: var(--primary);
    height: 40px;
  }
}

.ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner {
  background-color: transparent;
  border-color: #D0D5DD;

  &::after {
    border-color: #fff;
  }
}

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #D0D5DD;
    border-color: #D0D5DD;

    &::after {
      border-color: #fff;
    }
}

.custom-form-radio-button {
  input[type="radio"] {
    accent-color: var(--primary);
  }

  input[type="radio"]:checked:after {
    accent-color: var(--primary);
  }

  @media screen and ( max-width: 768px ) {
    margin-left: 20px;
  }

}

.custom-file-upload {
  display: flex;
  gap: 8px;
  flex-direction: column;

  .custom-file-upload-input {
    display: flex;
    gap: 8px;
    align-items: baseline;

    p {
      color: #667085;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .custom-uploaded-file__icon {
    width: 40px;
    height: 40px;
    padding: 4px;
    border-radius: 50%;
    background-color: #F9FAFB;

    svg {
      width: 100%;
      height: 100%;
      color: #475467;
      padding: 8px;
      border-radius: 50%;
      background-color: #F2F4F7;
    }
  }

  .custom-uploaded-file {
    width: 100%;
    gap: 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px 16px;
    border: 1px solid #475467;
    background: var(--white);

    h2 {
      color: #344054;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
    }

    span {
      color: #667085;
      font-size: 12px;
      line-height: 18px;
    }
  }
}