.eon-modal,
.eon-modal-confirmation {
  .btn-close {
    border: none;
    background: transparent;
  }

  .modal-header {
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px 16px;

    .modal-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: var(--primary);
    }
  }

  .modal-body {
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    padding: 0px;
    gap: 10px;
  }

  .modal-content {
    border-radius: 10px;
  }

  .modal-footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.eon-modal {
  .modal-header {
    background-color: #fff;
    border-bottom: 0px;
  }

  .modal-footer {
    background-color: #fff;
    border-top: 0px;
    padding-top: 16px;
  }
}

.eon-modal-confirmation {
  .modal-header {
    border-bottom: 1px solid #EAECF0;
  }

  .modal-footer {
    border-top: 1px solid #EAECF0;
    padding: 8px 16px;
  }
}