.custom-card {
  display: grid;
  padding: 16px;
  border-radius: 10px;
  gap: 16px;
  font-size: 12px;
  color: #1c212d;

  .custom-card__header {
    h2 {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      color: #667085;
    }
  }

  .custom-card__footer {
    display: flex;
    padding-top: 16px;
    justify-content: space-between;
    border-top: 1px solid #EAECF0;

    button {
      border-radius: 8px;
    }
  }
}

.custom-card {
  background-color: white;

  &-default {
    border: 1px solid #eaecf0;
  }

  &-borderless {
    border: none;
  }

  &-dashed {
    border: 1px dashed #667085;
  }
}

.custom-card-grey-default {
  background: #F9FAFB;
  border: 1px solid #eaecf0;
}

.custom-card-grey-borderless {
  background: #F9FAFB;
  border: none;
}

.custom-card-radio {
  padding: 24px 16px;
  background: #F9FAFB;
  border: 1px solid #667085;
}

.custom-card-grey-borderless,
.custom-card-borderless,
.custom-card-dashed {
  .custom-card__header h2 {
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
    color: #98a2b3;
  }
}