@import "stylesheets/layout/_layout-variables.scss";

.eon-topbar-cont {
  margin: auto;
  display: grid;
  align-items: center;
  background: $app-header-background;
  display: flex !important;
  height: $app-header-height;
  border-bottom: $app-header-border-bottom;
  justify-content: space-between !important;
  padding: 16px 100px !important;
  
  @media screen and (max-width: 770px) {
    padding: 16px 4% !important;
  }

  .eon-topbar-logo-cont {
    display: flex;
    align-items: center;
    grid-column: auto / span 3;
    width: 100%;
    gap: 16px;

    img {
      width: 20vw;
      max-width: 150px;
      cursor: pointer;
    }
  }
  .eon-topbar-search-cont {
    grid-column: auto / span 6;
    width: 100%;
    position: relative;

    input {
      padding: 0px 16px;
      height: 50px;
      background-color: transparent;
      border: 1px solid #b0b0b0;
      position: relative;
      color: #fff;

      &:focus {
        border-color: black;
      }

      &::placeholder {
        color: #b0b0b0;
        opacity: 1; /* Firefox */
      }
      
      &::-ms-input-placeholder { /* Edge 12-18 */
        color: #b0b0b0;
      }
    }

    .eon-topbar-search-icon {
      width: 42px;
      color: #fff;
      max-width: initial;
      height: 42px;
      line-height: initial;
      padding: 0 12px;
      position: absolute;
      right: 3px;
      top: 5px;
      font-size: 15px;
      line-height: 1;
      background: transparent !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .eon-topbar-cart-cont {
    grid-column: auto / span 3;
    display: flex;
    font-size: 17px;
    align-items: center;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      color: white;
    }

    .eon-topbar-cart-count {
      background: #fff;
      color: var(--primary);
      line-height: 1;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: initial;
    }
  }

  @media screen and (max-width: 1000px) {
    height: 70px;
    padding: 8px 24px !important;

    .eon-topbar-logo-cont {
      img {
        width: 100px;
      }
    }
  }
}

.eon-topbar-menu-cont {
  @media screen and (max-width: 1000px) {
    display: none;
  }
  padding-left: calc( 5.04166667% - 15px );
  padding-right: 5.04166667%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: var(--primary);

  .eon-topbar-menu-item {
    height: 50px;
    line-height: 50px;
    position: relative;
    color: #ffff;
    padding-left: 15px;
    padding-right: 15px;
    transition: ease-in-out;
    position: relative;
    text-decoration: none;

    svg {
      position: absolute;
      right: -3px;
      top: 52%;
      transform: translateY(-50%);
    }

    &:hover {
      .eon-topbar-sub-menu-cont {
        display: block;
      }
      &::after {
        width: 100%; // Expand the underline to 100% on hover
      }
    }
  
    &::after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 0; // Initial width is 0
      height: 2px; // Set your desired underline height
      background-color: #ffff; // Set your desired underline color
      transition: width 0.3s ease; // Add a transition for a smooth effect
    }

    .eon-topbar-sub-menu-cont {
      position: absolute;
      padding: 10px 20px;
      margin: 0;
      min-width: 200px;
      max-width: 270px;
      display: none;

      .eon-topbar-sub-menu-item {
        position: relative;
        display: block;
        margin: 6px 0;
        width: 100%;
        opacity: 0;
        line-height: 1.5;
        text-align: left;
        color: var(--primary);
        font-weight: 400;
        animation: fadeIn 0.5s ease forwards;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@keyframes fadeIn {
  to {
    opacity: 1; // Set opacity to 1 at the end of the animation
  }
}

.eon-topbar-cont-mobile {
  @media screen and ( min-width: 1000px ) {
    display: none
  }
  padding: 15px 4% !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--primary);
  justify-content: space-between;

  svg {
    padding: 0 3px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    line-height: 1;
    width: 50px;
    height: 30px;
    cursor: pointer;
  }

  .eon-topbar-cont-mobile-img{
    max-width: 120px;
  }

  .eon-topbar-cart-cont {
    display: flex;
    font-size: 17px;
    align-items: center;
    cursor: pointer;

    svg {
      width: 25px;
      height: 25px;
      color: white;
    }

    .eon-topbar-cart-count {
      background: #fff;
      color: var(--primary);
      line-height: 1;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: initial;
    }
  }
}

.btn-dark {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;

  &:hover {
    color: #D0D5DD !important;
  }
}

.eon-topbar-navigation-btn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  color:#667085;
  border: none;
  background-color: #FCFCFD;

  &:hover,
  &.selected {
    background-color: #E9E9E9;
  }

  &.selected {
    color: #1A1A1A;
  }
}