// Sidebar Base

.app-sidebar {
  width: $app-sidebar-width;
  display: flex;
  z-index: 11;
  overflow: hidden;
  height: calc(100vh - 125px);
  min-width: $app-sidebar-width;
  position: relative;
  background-color: var(--brand);
  flex: 0 0 $app-sidebar-width;
  transition: all 0.2s;

  .app-sidebar__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
  }

  .app-sidebar__inner {
    padding: 16px 8px;
  }

  .scrollbar-container {
    z-index: 15;
    width: 100%;
  }

  .app-sidebar-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.05;
    background-size: cover;
    z-index: 10;
  }

  .app-header__logo {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 11;
  }

  &.sidebar-shadow {
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  }
}

.app-sidebar__heading {
  text-transform: uppercase;
  font-size: $font-size-xs;
  margin: 0 16px 4px;
  font-weight: bold;
  color: #97999E;
  white-space: nowrap;
  position: relative;
}

.sidebar-mobile-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  opacity: 0.6;
  left: 0;
  top: 0;
  z-index: 12;
}

// Sidebar Components

@import "modifiers/navmenu-vertical";

// Sidebar Modifiers

@import "themes/sidebar-light";
@import "themes/sidebar-dark";
//@import "themes/sidebar-inverted";

@import "modifiers/fixed-sidebar";
@import "modifiers/closed-sidebar";
